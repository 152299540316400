import React from 'react';
import { Container, Row, Col, Button, Navbar, Nav, Card, Image, NavDropdown } from 'react-bootstrap';
import logo from '../assets/logo.png';
import { FaLine, FaShippingFast, FaShieldAlt, FaUserTie } from "react-icons/fa";

const NavbarHome = () => {
  return (
    <div>
      <div className="contact-bar">
        <Container>
          <Row>
            <Col className="ms-auto text-end">
              <span>Call Center : 08-002-03350</span>
              <span className="line-contact"><FaLine /> @siamsms</span>
            </Col>
          </Row>
        </Container>
      </div>

      <Navbar expand="lg" className="custom-navbar">
        <Container>
          <Navbar.Brand href="/">
            <img
              style={{ marginRight: '1rem' }}
              src={logo}
              width="200"
              height="45"
              className="d-inline-block align-center"
              alt="SIAM-SMS logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/">หน้าหลัก</Nav.Link>
              <Nav.Link href="/price">ราคา</Nav.Link>
              <Nav.Link href="/report">แจ้งปัญหา</Nav.Link>
              <NavDropdown title="บริการของเรา" id="basic-nav-dropdown">
                <NavDropdown.Item href="/smsotp">SMS OTP</NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <Nav className="ms-auto">
              <Button href="/login" className="login-button">เข้าสู่ระบบ</Button>
              <Button href="/register" className="trial-button">สมัครสมาชิก</Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

    </div>
  );
};

export default NavbarHome;